<template>
  <ConnectorContentBlock>
    <template #title>
      {{ $t('DbImportDialog.Ui.tabArrivals:at') }}
    </template>
    <ConnectorQueryBlock
      v-model="queryModel"
      :label="$t('Web.DbImport.LabelQuery')"
      :placeholder="$t('Web.DbImport.PlaceholderQuery')"
      :stubs="stubs"
    >
      <template #actions>
        <SlButton
          type="submit"
          data-preview
        >
          {{ $t('DbImportDialog.Ui.btPreview') }}
        </SlButton>
        <SlButton
          variant="secondary"
          color="grey"
          type="submit"
          data-export
        >
          {{ $t('DbImportDialog.Ui.btExportToCsv') }}
        </SlButton>
      </template>
    </ConnectorQueryBlock>
    <div class="connector-content-additional">
      <div class="connector-content-additional__title">
        {{ $t('Web.DbImport.TitleToReceiveSetting') }}
      </div>
      <div class="connector-content-additional__subtitle">
        {{ $t('Web.DbImport.TextToReceiveSetting') }}
      </div>
      <div class="connector-content-additional__content">
        <ConnectorMatchBlock
          :left-label="$t('Web.DbImport.ToReceiveSlValue')"
          :right-label="$t('Web.DbImport.ToReceiveDbValue')"
        >
          <div class="connector-content-row">
            <div class="w-150 body-1 grey-80">
              {{ $t('DbImportDialog.Ui.lbPurchase') }}
            </div>
            <SlInput
              v-model="purchaseModel"
              class="w-240"
              :placeholder="$t('Web.DbImport.PlaceholderToReceiveValue')"
            />
          </div>
          <div class="connector-content-row">
            <div class="w-150 body-1 grey-80">
              {{ $t('DbImportDialog.Ui.lbTransfer') }}
            </div>
            <SlInput
              v-model="transferModel"
              class="w-240"
              :placeholder="$t('Web.DbImport.PlaceholderToReceiveValue')"
            />
          </div>
          <div class="connector-content-row">
            <div class="w-150 body-1 grey-80">
              {{ $t('DbImportDialog.Ui.lbWork') }}
            </div>
            <SlInput
              v-model="manufactureModel"
              class="w-240"
              :placeholder="$t('Web.DbImport.PlaceholderToReceiveValue')"
            />
          </div>
        </ConnectorMatchBlock>
      </div>
    </div>
  </ConnectorContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import ConnectorQueryBlock from '@/components/Connections/Common/ConnectorQueryBlock';
import ConnectorMatchBlock from '@/components/Connections/Common/ConnectorMatchBlock';
import { tabKeys } from '@/config/connection/dbRelated.config';

export default {
  name: 'OrdersToReceive',
  components: {
    ConnectorQueryBlock,
    ConnectorContentBlock,
    ConnectorMatchBlock
  },
  props: {
    stubs: {
      type: Array,
      default: () => ([])
    }
  },
  inject: [
    'setSettings'
  ],
  computed: {
    ...mapState({
      tabData: state => state.dbRelated[tabKeys.ORDERS_RECEIVE]
    }),
    queryModel: {
      get() {
        return this.tabData.query;
      },
      set(value) {
        this.setSettings({ value, key: 'query' });
      }
    },
    purchaseModel: {
      get() {
        return this.tabData.purchase;
      },
      set(value) {
        this.setSettings({ value, key: 'purchase' });
      }
    },
    transferModel: {
      get() {
        return this.tabData.transfer;
      },
      set(value) {
        this.setSettings({ value, key: 'transfer' });
      }
    },
    manufactureModel: {
      get() {
        return this.tabData.manufacture;
      },
      set(value) {
        this.setSettings({ value, key: 'manufacture' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.connector-content-additional__content {
  margin-top: 12px;
}
</style>