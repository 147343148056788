var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ConnectorContentBlock',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.TabInvForecast'))+" ")]},proxy:true}])},[_c('ConnectorQueryBlock',{attrs:{"label":_vm.$t('Web.DbImport.LabelQuery'),"placeholder":_vm.$t('Web.DbImport.PlaceholderQueryExport'),"stubs":_vm.stubs},model:{value:(_vm.queryModel),callback:function ($$v) {_vm.queryModel=$$v},expression:"queryModel"}}),_c('div',{staticClass:"connector-content-additional"},[_c('div',{staticClass:"connector-content-additional__title"},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.TitleTestExport'))+" ")]),_c('div',{staticClass:"connector-content-additional__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.TextTestExport'))+" ")]),_c('div',{staticClass:"connector-content-additional__content"},[_c('div',{staticClass:"connector-content-row"},[_c('SlValidate',{staticClass:"w-240",attrs:{"vid":"itemCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugCode'),"is-invalid":invalid},model:{value:(_vm.itemCodeModel),callback:function ($$v) {_vm.itemCodeModel=$$v},expression:"itemCodeModel"}})]}}])})],1),_c('div',{staticClass:"connector-content-row"},[_c('SlInput',{staticClass:"w-240",attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugReportLocation')},model:{value:(_vm.locationModel),callback:function ($$v) {_vm.locationModel=$$v},expression:"locationModel"}})],1),_c('div',{staticClass:"connector-content-row"},[_c('SlValidate',{staticClass:"w-240",attrs:{"vid":"forecastDate","rules":"sl_date_between|sl_valid_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlDatePicker',{attrs:{"label":_vm.$t('Web.DbImport.LabelForecastDate'),"is-invalid":invalid},model:{value:(_vm.forecastDateModel),callback:function ($$v) {_vm.forecastDateModel=$$v},expression:"forecastDateModel"}})]}}])}),_c('SlTimePicker',{staticClass:"w-240",attrs:{"label":_vm.$t('Web.DbImport.LabelForecastTime')},model:{value:(_vm.forecastTimeModel),callback:function ($$v) {_vm.forecastTimeModel=$$v},expression:"forecastTimeModel"}})],1),_c('div',{staticClass:"connector-content-row"},[_c('SlValidate',{staticClass:"w-240",attrs:{"vid":"forecastDateStart","rules":"sl_date_between|sl_valid_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlDatePicker',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugForecastPeriodStart'),"is-invalid":invalid},model:{value:(_vm.forecastDateStartModel),callback:function ($$v) {_vm.forecastDateStartModel=$$v},expression:"forecastDateStartModel"}})]}}])}),_c('SlValidate',{staticClass:"w-240",attrs:{"vid":"forecastDateEnd","rules":"sl_date_between|sl_valid_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlDatePicker',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugForecastPeriodEnd'),"is-invalid":invalid},model:{value:(_vm.forecastDateEndModel),callback:function ($$v) {_vm.forecastDateEndModel=$$v},expression:"forecastDateEndModel"}})]}}])})],1),_c('div',{staticClass:"connector-content-row"},[_c('SlValidate',{attrs:{"vid":"demandForecast","rules":"required|min_value:0|max_value:1000000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugDemandForecast'),"type":"number","is-invalid":invalid,"inline":""},model:{value:(_vm.demandForecastModel),callback:function ($$v) {_vm.demandForecastModel=$$v},expression:"demandForecastModel"}})]}}])}),_c('SlValidate',{attrs:{"vid":"orderingPlan","rules":"required|min_value:0|max_value:1000000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugPurchasePlan'),"type":"number","is-invalid":invalid,"inline":""},model:{value:(_vm.orderingPlanModel),callback:function ($$v) {_vm.orderingPlanModel=$$v},expression:"orderingPlanModel"}})]}}])}),_c('SlValidate',{attrs:{"vid":"inventoryProjection","rules":"required|min_value:0|max_value:1000000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('DbImportDialog.Ui.lbDebugInvProjection'),"type":"number","is-invalid":invalid,"inline":""},model:{value:(_vm.inventoryProjectionModel),callback:function ($$v) {_vm.inventoryProjectionModel=$$v},expression:"inventoryProjectionModel"}})]}}])})],1),_c('SlButton',{attrs:{"variant":"secondary","color":"grey","type":"submit","data-test-export":""}},[_vm._v(" "+_vm._s(_vm.$t('DbImportDialog.Ui.btDebugForecastReportExec'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }