var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ConnectorContentBlock',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true}])},[(_vm.$sl_isEditingRoute)?_c('div',{staticClass:"connector-content-block__row connector-content-block__row--divider"},[_c('SlInfoItem',{attrs:{"label":_vm.$t('DbInsideImport.Ui.lbGroupBy'),"value":_vm.groupByLabel}}),_c('SlInfoItem',{attrs:{"label":_vm.startFromKeyText,"value":_vm.startFromValueText}})],1):_vm._e(),_c('div',{staticClass:"connector-content-row w-240"},[_c('SlValidate',{attrs:{"vid":"startDate","rules":"required|sl_date_between|sl_valid_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlDatePicker',{attrs:{"label":_vm.$t('DbInsideImport.Ui.lbStartDate'),"is-invalid":invalid},model:{value:(_vm.startDateModel),callback:function ($$v) {_vm.startDateModel=$$v},expression:"startDateModel"}})]}}])})],1),(!_vm.$sl_isEditingRoute)?_c('div',{staticClass:"connector-content-row"},[_c('SlControl',{attrs:{"options":_vm.groupByOptions,"label":_vm.$t('DbInsideImport.Ui.lbGroupBy'),"description":_vm.$t('Web.DbImport.LabelDescrGroupBy')},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SlInfoButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(_vm.$t('Web.DbImport.TooltipGroupBy'))),expression:"getTooltip($t('Web.DbImport.TooltipGroupBy'))"}]})]},proxy:true}],null,false,2622804443),model:{value:(_vm.groupByModel),callback:function ($$v) {_vm.groupByModel=$$v},expression:"groupByModel"}}),(_vm.groupByModel === _vm.groupBy.MONTH)?_c('SlValidate',{key:"startFromDay",attrs:{"rules":{
        required: true,
        between: {
          min: 1,
          max: 31,
          message: _vm.$t('Web.Validation.InvalidValue')
        }
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"min":_vm.minStart,"max":_vm.maxStart,"label":_vm.$t('Web.DbImport.LabelStartFromDay'),"description":_vm.$t('Web.DbImport.LabelDescrStartFrom'),"is-invalid":invalid},model:{value:(_vm.startFromModel),callback:function ($$v) {_vm.startFromModel=$$v},expression:"startFromModel"}})]}}],null,false,3375474663)}):_vm._e(),(_vm.groupByNoMonth)?_c('SlSelect',{attrs:{"select-label":_vm.$t('Web.DbImport.LabelStartFrom'),"options":_vm.startFromOptions,"disabled":_vm.groupByModel === _vm.groupBy.DAY,"inline":""},model:{value:(_vm.startFromModel),callback:function ($$v) {_vm.startFromModel=$$v},expression:"startFromModel"}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }