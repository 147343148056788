<template>
  <ConnectorContentBlock>
    <template #title>
      {{ $t('DbImportDialog.Ui.tabMainQuery:at') }}
    </template>
    <ConnectorQueryBlock
      v-model="queryModel"
      :label="$t('Web.DbImport.LabelQueryOrder')"
      :placeholder="`${$t('Web.DbImport.PlaceholderQueryOrderSelect')}\n${$t('Web.DbImport.PlaceholderQueryOrderBy')}`"
      :stubs="stubs"
    >
      <SlValidate
        v-slot="{ invalid }"
        vid="startDate"
        rules="required|sl_date_between|sl_valid_date"
      >
        <SlDatePicker
          v-model="startDateModel"
          :label="$t('DbInsideImport.Ui.lbStartDate')"
          class="w-240"
          :is-invalid="invalid"
        />
      </SlValidate>
      <template #actions>
        <SlButton
          type="submit"
          data-preview
        >
          {{ $t('DbImportDialog.Ui.btPreview') }}
        </SlButton>
        <SlButton
          variant="secondary"
          color="grey"
          type="submit"
          data-export
        >
          {{ $t('DbImportDialog.Ui.btExportToCsv') }}
        </SlButton>
      </template>
    </ConnectorQueryBlock>
  </ConnectorContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import ConnectorQueryBlock from '@/components/Connections/Common/ConnectorQueryBlock';
import { tabKeys } from '@/config/connection/dbRelated.config';

export default {
  name: 'Transactions',
  components: {
    ConnectorQueryBlock,
    ConnectorContentBlock
  },
  props: {
    stubs: {
      type: Array,
      default: () => ([])
    }
  },
  inject: [
    'setSettings'
  ],
  computed: {
    ...mapState({
      commonData: state => state.dbRelated.commonData,
      tabData: state => state.dbRelated[tabKeys.TRANSACTIONS]
    }),
    queryModel: {
      get() {
        return this.tabData.query;
      },
      set(value) {
        this.setSettings({ value, key: 'query' });
      }
    },
    startDateModel: {
      get() {
        return this.commonData.startDate;
      },
      set(value) {
        this.setSettings({ value, key: 'startDate', tab: 'commonData' });
      }
    }
  }
};
</script>
