<template>
  <div class="connector-match-block">
    <div class="connector-content-row">
      <div class="body-4-md grey-60 w-150">
        {{ leftLabel }}
      </div>
      <div
        v-if="middleLabel"
        class="body-4-md grey-60 w-240"
      >
        {{ middleLabel }}
      </div>
      <div class="body-4-md grey-60 w-240">
        {{ rightLabel }}
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ConnectorMatchBlock',
  props: {
    leftLabel: {
      type: String,
      default: ''
    },
    middleLabel: {
      type: String,
      default: ''
    },
    rightLabel: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";
@import "@/style/utils/_mixins.scss";

.connector-match-block {
  .connector-content-row {
    position: relative;

    width: fit-content;
    column-gap: 32px;

    .required {
      @include required-asterisk;
    }

    &:first-child {
      margin-bottom: 16px;
    }

    &:not(:first-child) {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 32px;
        height: 1px;

        background: $grey-40;

        transform: translate(-50%, -50%);
        content: '';
      }
    }
  }
}
</style>