<template>
  <ConnectorContentBlock>
    <template #title>
      {{ $t('DbImportDialog.Ui.tabBatches:at') }}
    </template>
    <ConnectorQueryBlock
      v-model="queryModel"
      :label="$t('Web.DbImport.LabelQuery')"
      :placeholder="$t('Web.DbImport.PlaceholderQuery')"
      :stubs="stubs"
    >
      <template #actions>
        <SlButton
          type="submit"
          data-preview
        >
          {{ $t('DbImportDialog.Ui.btPreview') }}
        </SlButton>
        <SlButton
          variant="secondary"
          color="grey"
          type="submit"
          data-export
        >
          {{ $t('DbImportDialog.Ui.btExportToCsv') }}
        </SlButton>
      </template>
    </ConnectorQueryBlock>
  </ConnectorContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import ConnectorQueryBlock from '@/components/Connections/Common/ConnectorQueryBlock';
import { tabKeys } from '@/config/connection/dbRelated.config';

export default {
  name: 'Expirations',
  components: {
    ConnectorQueryBlock,
    ConnectorContentBlock
  },
  props: {
    stubs: {
      type: Array,
      default: () => ([])
    }
  },
  inject: [
    'setSettings'
  ],
  computed: {
    ...mapState({
      tabData: state => state.dbRelated[tabKeys.BATCH_EXPIRATIONS]
    }),
    queryModel: {
      get() {
        return this.tabData.query;
      },
      set(value) {
        this.setSettings({ value, key: 'query' });
      }
    }
  }
};
</script>