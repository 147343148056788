<template>
  <ConnectorContentBlock>
    <template #title>
      {{ title }}
    </template>
    <div
      v-if="$sl_isEditingRoute"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('DbInsideImport.Ui.lbGroupBy')"
        :value="groupByLabel"
      />
      <SlInfoItem
        :label="startFromKeyText"
        :value="startFromValueText"
      />
    </div>
    <div class="connector-content-row w-240">
      <SlValidate
        v-slot="{ invalid }"
        vid="startDate"
        rules="required|sl_date_between|sl_valid_date"
      >
        <SlDatePicker
          v-model="startDateModel"
          :label="$t('DbInsideImport.Ui.lbStartDate')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <div
      v-if="!$sl_isEditingRoute"
      class="connector-content-row"
    >
      <SlControl
        v-model="groupByModel"
        :options="groupByOptions"
        :label="$t('DbInsideImport.Ui.lbGroupBy')"
        :description="$t('Web.DbImport.LabelDescrGroupBy')"
      >
        <template #icon>
          <SlInfoButton
            v-tooltip="getTooltip($t('Web.DbImport.TooltipGroupBy'))"
          />
        </template>
      </SlControl>
      <SlValidate
        v-if="groupByModel === groupBy.MONTH"
        v-slot="{ invalid }"
        key="startFromDay"
        :rules="{
          required: true,
          between: {
            min: 1,
            max: 31,
            message: $t('Web.Validation.InvalidValue')
          }
        }"
      >
        <SlControlInput
          v-model="startFromModel"
          :min="minStart"
          :max="maxStart"
          :label="$t('Web.DbImport.LabelStartFromDay')"
          :description="$t('Web.DbImport.LabelDescrStartFrom')"
          :is-invalid="invalid"
        />
      </SlValidate>
      <SlSelect
        v-if="groupByNoMonth"
        v-model="startFromModel"
        :select-label="$t('Web.DbImport.LabelStartFrom')"
        :options="startFromOptions"
        :disabled="groupByModel === groupBy.DAY"
        inline
      />
    </div>
  </ConnectorContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import { tabKeys } from '@/config/connection/dbRelated.config';
import { groupBy, groupByOptions } from '@/config/connection';
import { weekDays } from '@/config/utils/dateInfo.config';

export default {
  name: 'Required',
  components: {
    ConnectorContentBlock
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  inject: [
    'getTooltip',
    'setSettings'
  ],
  data() {
    return {
      groupBy,
      minStart: 1,
      maxStart: 31
    };
  },
  computed: {
    ...mapState({
      commonData: state => state.dbRelated.commonData,
      tabData: state => state.dbRelated[tabKeys.REQUIRED]
    }),
    groupByOptions() {
      return groupByOptions(this);
    },
    startFromOptions() {
      return weekDays(this);
    },
    groupByNoMonth() {
      return [groupBy.WEEK, groupBy.DAY].includes(this.groupByModel);
    },
    startFromKeyText() {
      return this.groupByNoMonth
        ? this.$t('Web.DbImport.LabelStartFrom')
        : this.$t('Web.DbImport.LabelStartFromDay');
    },
    startFromValueText() {
      return this.groupByNoMonth
        ? this.startFromOptions.find(item => item.value === this.startFromModel).label
        : this.startFromModel;
    },
    startDateModel: {
      get() {
        return this.commonData.startDate;
      },
      set(value) {
        this.setSettings({ value, key: 'startDate', tab: 'commonData' });
      }
    },
    groupByModel: {
      get() {
        return this.tabData.groupBy;
      },
      set(value) {
        this.setSettings({ value, key: 'groupBy' });
        this.startFromModel = [groupBy.WEEK, groupBy.DAY].includes(value) ? 0 : 1;
      }
    },
    groupByLabel() {
      return this.groupByOptions.find(option => option.value === this.groupByModel)?.label
        || this.groupByOptions[0].label;
    },
    startFromModel: {
      get() {
        return this.tabData.startFrom;
      },
      set(value) {
        this.setSettings({ value, key: 'startFrom' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/connectors/common/settings.scss";
</style>
