<template>
  <ConnectorContentBlock>
    <template #title>
      {{ $t('Web.DbImport.TabInvReport') }}
    </template>
    <ConnectorQueryBlock
      v-model="queryModel"
      :label="$t('Web.DbImport.LabelQuery')"
      :placeholder="$t('Web.DbImport.PlaceholderQueryExport')"
      :stubs="stubs"
    />
    <div class="connector-content-additional">
      <div class="connector-content-additional__title">
        {{ $t('Web.DbImport.TitleTestExport') }}
      </div>
      <div class="connector-content-additional__subtitle">
        {{ $t('Web.DbImport.TextTestExport') }}
      </div>
      <div class="connector-content-additional__content">
        <div class="connector-content-row">
          <SlValidate
            vid="itemCode"
            rules="required"
            v-slot="{ invalid }"
          >
            <SlInput
              v-model="itemCodeModel"
              :label="$t('DbImportDialog.Ui.lbDebugCode')"
              :is-invalid="invalid"
              class="w-240"
            />
          </SlValidate>
        </div>
        <div class="connector-content-row">
          <SlInput
            v-model="locationModel"
            :label="$t('DbImportDialog.Ui.lbDebugReportLocation')"
            class="w-240"
          />
        </div>
        <div class="connector-content-row">
          <SlValidate
            vid="onHand"
            rules="required|min_value:0|max_value:1000000"
            v-slot="{ invalid }"
          >
            <SlInput
              v-model="onHandModel"
              :label="$t('DbImportDialog.Ui.lbDebugReportOnHand')"
              type="number"
              :is-invalid="invalid"
              inline
            />
          </SlValidate>
          <SlValidate
            vid="supplyDays"
            rules="required|min_value:0|max_value:1000000"
            v-slot="{ invalid }"
          >
            <SlInput
              v-model="supplyDaysModel"
              :label="$t('DbImportDialog.Ui.lbDebugReportDays')"
              type="number"
              :is-invalid="invalid"
              inline
            />
          </SlValidate>
          <SlValidate
            vid="receiveQty"
            rules="required|min_value:0|max_value:1000000"
            v-slot="{ invalid }"
          >
            <SlInput
              v-model="receiveQtyModel"
              :label="$t('DbImportDialog.Ui.lbDebugReportInTransition')"
              type="number"
              :is-invalid="invalid"
              inline
            />
          </SlValidate>
        </div>
        <SlButton
          variant="secondary"
          color="grey"
          type="submit"
          data-test-export
        >
          {{ $t('DbImportDialog.Ui.btDebugForecastReportExec') }}
        </SlButton>
      </div>
    </div>
  </ConnectorContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import ConnectorQueryBlock from '@/components/Connections/Common/ConnectorQueryBlock';
import { tabKeys } from '@/config/connection/dbRelated.config';

export default {
  name: 'InventoryReport',
  components: {
    ConnectorQueryBlock,
    ConnectorContentBlock
  },
  props: {
    stubs: {
      type: Array,
      default: () => ([])
    }
  },
  inject: [
    'setSettings'
  ],
  computed: {
    ...mapState({
      tabData: state => state.dbRelated[tabKeys.INVENTORY_REPORT]
    }),
    queryModel: {
      get() {
        return this.tabData.query;
      },
      set(value) {
        this.setSettings({ value, key: 'query' });
      }
    },
    itemCodeModel: {
      get() {
        return this.tabData.itemCode;
      },
      set(value) {
        this.setSettings({ value, key: 'itemCode' });
      }
    },
    locationModel: {
      get() {
        return this.tabData.location;
      },
      set(value) {
        this.setSettings({ value, key: 'location' });
      }
    },
    onHandModel: {
      get() {
        return this.tabData.onHand ?? 0;
      },
      set(value) {
        this.setSettings({ value, key: 'onHand' });
      }
    },
    supplyDaysModel: {
      get() {
        return this.tabData.supplyDays ?? 0;
      },
      set(value) {
        this.setSettings({ value, key: 'supplyDays' });
      }
    },
    receiveQtyModel: {
      get() {
        return this.tabData.receiveQty ?? 0;
      },
      set(value) {
        this.setSettings({ value, key: 'receiveQty' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_variable.scss";

.connector-content-additional {
  .connector-content-row {
    align-items: flex-start;

    width: fit-content;

    &:nth-child(3) {
      &::v-deep .sl-validation-provider {
        max-width: $input-min-width;
      }
    }
  }
}
</style>
